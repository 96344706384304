export const financials = {
  pl: 'PL',
  bs: 'BS',
  fiscalPeriod: '決算期',
  year: '年月',
  yearAdornment: '年',
  period: '月期',
  periodAdornment: '月',
  amountOfSales: '売上高',
  ordinary: '経常利益',
  net: '当期純利益',
  totalAssets: '資産合計',
  profit: '営業利益',
  depreciation: '減価償却費',
  ebitda: 'EBITDA',
  netAssets: '純資産',
  interestBearingDebt: '有利子負債',
  cashAndDeposits: '現預金',
  financialPeriod: '決算期',
}
