export const production = {
  host: 'ma-navigator.com',
  imageFluxHost: 'p1-256b38d2.imageflux.jp',
  gtmId: 'GTM-WN6HFDR',
  ENABLE_SENTRY: true,
  ENABLE_MICROCMS: false,
  ENABLE_NEWT: true,
  IS_SHOW_TSR_SLUG: ['navi'],
  ENABLE_ALLIANCE_SLUG: ['navi'],
  NAVI_TENANT_SLUG: 'navi',
  ENABLE_LONGLIST_EXPORT_PATTERN: ['navi'],
}
