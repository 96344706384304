import React, { ReactNode } from 'react'

const numberRegex = /\d/g

export const EMPTY_STRING = '---'
export const CIRCLE = '○'
export const EMPTY_RESULT = '-,---'

export const checkNumberCif = (value: string): boolean => {
  const countNumber = (value.match(numberRegex) || []).length
  return countNumber >= 6 && countNumber <= 20 && countNumber === value.length
}

export const convertToRegex = (value: string): RegExp => {
  return new RegExp(value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&'))
}

export const convertZipcode = (value?: string): string => {
  if (!value) return ''
  if (value.includes('-') || value.length < 4) {
    return value
  }
  return `${value.slice(0, 3)}-${value.slice(3, value.length)}`
}

export function extractStringsFromReactNode(node: ReactNode): string {
  if (typeof node === 'string') {
    return node
  }

  if (Array.isArray(node)) {
    // If the node is an array, map through its elements recursively.
    return node.map(extractStringsFromReactNode).join('')
  }

  if (React.isValidElement(node)) {
    // If the node is a React element, extract the children and process them.
    const children = React.Children.toArray(node.props.children)
    return children.map(extractStringsFromReactNode).join('')
  }

  return ''
}
