import { useState, useContext, createContext, Dispatch, FC, ReactNode } from 'react'

interface FlashProps {
  message: string | ReactNode
  type: 'success' | 'info' | 'warning' | 'error'
  isTimeout?: boolean
  top?: number
}
interface FlashContextType {
  flash: null | FlashProps
  setFlash: (props: FlashProps) => void | Dispatch<FlashProps>
}

const FlashContext = createContext<FlashContextType>({
  flash: null,
  setFlash: (props: FlashProps) => console.log(props),
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFlash() {
  return useContext(FlashContext)
}

export const FlashProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [flash, setFlash] = useState<null | FlashProps>(null)
  const value = { flash, setFlash }
  return <FlashContext.Provider value={value}>{children}</FlashContext.Provider>
}
