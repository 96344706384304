export const role = {
  label: 'ロール',
  1: '売り手',
  2: '買い手',
  3: '専門家',
  4: '契約管理者',
  5: 'アドミン',
  6: '業務管理者',
  7: '一般ユーザー',
  8: '支部管理者',
  9: '支店一般ユーザー',
  10: 'ハンズオンユーザー',
  11: '支店閲覧ユーザー',
}
