import React, { ReactElement, useEffect } from 'react'
import { NextPage } from 'next'
import NextApp, { AppContext } from 'next/app'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { Noto_Sans_JP } from '@next/font/google'
import 'styles/globals.css'
import axios from 'axios'
import Head from 'next/head'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { CacheProvider, EmotionCache } from '@emotion/react'
import TagManager from 'react-gtm-module'
import { SWRConfig } from 'swr'
import * as Sentry from '@sentry/nextjs'
import parser from 'ua-parser-js'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { config } from 'config'
import { AxiosProvider } from 'context/axiosContext'

import { AuthProvider, useAuth } from 'context/authContext'
import { FlashProvider, useFlash } from 'context/flashContext'
import { LoadingProvider } from 'context/loadingContext'
import { DmNotificationProvider } from 'context/dmNotificationContext'
import { themeSp, theme } from 'styles/theme'
import createEmotionCache from 'utils/createEmotionCache'
import { sha256 } from 'utils/hash'
import { BlankLayout } from 'components/modules/BlankLayout'
import { swrGlobalOptions } from 'utils/swrGlobalOptions'
import { Flash } from 'components/modules/Flash'
import { role } from 'public/locales/ja/model/role'
import { common } from 'public/locales/ja/common'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
Sentry.init({
  dsn: SENTRY_DSN || 'https://60c23bf6d75643ad846dae2c16b82f3f@o1306422.ingest.sentry.io/6564101',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  // enabled only in production environment
  enabled: config.ENABLE_SENTRY,
  environment: process.env.APP_ENV || 'development',
})

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactElement
}
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  emotionCache?: EmotionCache
  deviceType?: string
}

if (process.env.USE_DOCKER === 'false') {
  const HOST = process.env.API_HOST || 'localhost'
  const PORT = process.env.API_PORT || '80'

  axios.defaults.baseURL = `http://${HOST}:${PORT}`
  axios.defaults.withCredentials = true
}

export const notoSansJP = Noto_Sans_JP({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '500', '700', '900'],
})

const clientSideEmotionCache = createEmotionCache()

const CommonSettings: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { setFlash } = useFlash()
  const { user } = useAuth()

  const router = useRouter()

  // MC only effect
  useEffect(() => {
    if (typeof window !== 'object') return
    // return if hostname has subdomain, which means the user is accessing DM pages
    if (window.location.hostname !== config.host) return
    if (!user) return

    const userRole = user.role
    if (userRole === 1 || userRole === 2 || userRole === 3) {
      sha256(user.id).then((hash) => {
        TagManager.dataLayer({
          dataLayer: {
            bank_name: user.banker?.bank?.name,
            branch_name: user.banker?.branches?.name,
            branch_id: user.banker?.branchId,
            user_role_name: role[userRole],
            user_id: hash,
            user_segment: user?.userSegment,
            event: 'userdata_ready',
          },
        })
      })
    }
  }, [user, router.pathname])

  return (
    <SWRConfig
      value={{
        ...swrGlobalOptions,
        onError: () => {
          // if (process.env.NODE_ENV === 'production') {
          //   Sentry.captureException(err)
          // }
          setFlash({ type: 'error', message: common.error.unknown })
        },
      }}
    >
      <AxiosProvider>{children}</AxiosProvider>
    </SWRConfig>
  )
}

const CommonProviders: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <FlashProvider>
        <LoadingProvider>
          <AuthProvider>
            <GoogleOAuthProvider clientId={config.GOOGLE_API_CLIENT_ID}>
              <DmNotificationProvider>
                <CommonSettings>{children}</CommonSettings>
                <Flash />
              </DmNotificationProvider>
            </GoogleOAuthProvider>
          </AuthProvider>
        </LoadingProvider>
      </FlashProvider>
    </>
  )
}

function App({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
  deviceType,
}: AppPropsWithLayout): React.ReactElement {
  useEffect(() => {
    if (config.gtmId) {
      TagManager.initialize({
        gtmId: config.gtmId,
      })
    }
  }, [])
  const router = useRouter()
  const query = router.query
  const isUtms =
    !!query?.utm_source || !!query?.utm_medium || !!query?.utm_campaign || !!query?.utm_term || !!query?.utm_content
  useEffect(() => {
    if (isUtms) {
      sessionStorage.setItem(
        'utm',
        JSON.stringify({
          utm_source: query?.utm_source,
          utm_medium: query?.utm_medium,
          utm_campaign: query?.utm_campaign,
          utm_term: query?.utm_term,
          utm_content: query?.utm_content,
          utm_info_at: new Date(),
        })
      )
    }
    const referer = window.sessionStorage.getItem('referer')
    if (!referer && window.document.referrer) {
      window.sessionStorage.setItem('referer', window.document.referrer)
    }
  })

  const renderLayout = () => {
    const { getLayout } = Component
    if (getLayout) {
      const pageLayout = getLayout(<Component {...pageProps} />)
      return (
        <CommonProviders>
          <BlankLayout>{pageLayout}</BlankLayout>
        </CommonProviders>
      )
    }
    return (
      <CommonProviders>
        <BlankLayout>
          <Component {...pageProps} />
        </BlankLayout>
      </CommonProviders>
    )
  }

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
      </Head>
      <ThemeProvider theme={deviceType === 'mobile' ? themeSp : theme}>
        <main className={notoSansJP.className}>{renderLayout()}</main>
      </ThemeProvider>
    </CacheProvider>
  )
}

App.getInitialProps = async (context: AppContext) => {
  let deviceType
  if (context.ctx.req) {
    deviceType = parser(context.ctx.req.headers['user-agent']).device.type || 'desktop'
  }

  return {
    ...NextApp.getInitialProps(context),
    deviceType,
  }
}

export default App
