import merge from 'lodash/merge'
import { base } from './base'
import { dev } from './dev'
import { demo } from './demo'
import { latest } from './latest'
import { staging } from './staging'
import { production } from './production'
import { mufg } from './mufg'

const env = process.env.APP_ENV

let override = {}
if (env === 'dev') override = dev
if (env === 'demo') override = demo
if (env === 'latest') override = latest
if (env === 'staging') override = staging
if (env === 'production') override = production
if (env === 'mufg') override = mufg

export const config: typeof base = merge(base, override)
