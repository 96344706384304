import { financials } from 'public/locales/ja/model/financials'

export const clients = {
  financials,
  cif: {
    label: '顧客管理番号(CIF/CMF)',
    invalid: '{{title}}は英数字で6桁〜20桁で入力してください',
    getInvalid: (title: string): string => `${title}は半角英数字で6桁〜20桁で入力してください`,
    duplicate: 'すでに登録済みのCIFです',
  },
  postRoute: {
    label: '掲載ルート',
  },
  rank: {
    label: '格付',
  },
  intimacy: {
    label: '親密度',
  },
  poster: '拠点起票者',
  name: '企業名',
  sectorLarge: '業種（大分類）',
  sectorMedium: '業種（中分類）',
  sectorSmall: '業種（小分類）',
  sectorMicro: '業種（細分類）',
  type: '',
  clientName: '企業名',
  banks: '',
  branch: '店番',
  managementBranch: {
    label: '管理店番',
    invalid: '管理店番は半角数字3~4桁で入力してください',
  },
  prefectures: '都道府県',
  acquisitionTargetArea: '対象地域',
  status: {
    1: '交渉前',
    10: '交渉中',
    99: 'アーカイブ',
  },
  hasTarget: {
    true: '有',
    false: '無',
  },
  clientRelations: '関連会社',
  notFound: '見つかりません',
  detail: '企業情報備考',
  placeholder: {
    zipcode: '1000001',
    address: '東京都千代田区千代田',
    detail: '過去のM&A実績や評価コメントなど',
    clientName: 'サンプル株式会社',
  },
  prefectureArea: {
    label: 'エリア',
  },
  zipcode: '郵便番号',
  address: '本店所在地',
  financialPeriod: '決算期',
  financialYear: '決算期',
  periodWithYear: '決算期',
  acquisitionClientPeriod: '決算期',
  isStrongBuyer: 'ストロングバイヤー',
  prefecture: '都道府県',
  needsId: 'ニーズID',
  interviewName: '面談名',
  scriber: '記入者',
  sectorLargeAndMedium: '業種（大 + 中）',
}
