export const common = {
  error: {
    normal: '申し訳ございません。エラーが発生しました。',
    serverError: '申し訳ございません。サーバーエラーが発生しました',
    unknown: 'エラーが発生しました',
    unauthorized: '権限がありません',
    formNotCompleted: '未入力の項目があります',
  },
  success: {
    normal: '送信に成功しました。',
    logged: 'ログインしました。',
  },
  yes: 'はい',
  no: 'いいえ',
  cancel: 'キャンセル',
  next: '次へ',
  charUnit: '字',
  example: '記入例',
  terms: {
    title: '利用規約',
  },
  privacy: {
    title: 'プライバシーポリシー',
  },
  dashboard: {
    title: 'ホーム',
  },
  dragDropFile: 'ファイルをドラッグ＆ドロップ',
  titleSelectFile: 'ファイルを選択',
  orClickToSelectFile: 'またはクリックしてファイルを選択してください',
  tenThousandYen: '{{num}}万円',
  getTenThousandYen: (num: number): string => `${num}万円`,
  other: 'その他',
  password: 'パスワード',
  newPassword: '新しいパスワード',
  lastNameKana: '姓（カナ）',
  firstNameKana: '名（カナ）',
  lastName: '姓',
  firstName: '名',
  getName: (lastName?: string, firstName?: string): string => `${lastName} ${firstName}さん`,
  lastNameRomaji: '姓（ローマ字）',
  firstNameRomaji: '名（ローマ字）',
  selectImageFiles: '※10MB以下の JPG、PNGファイルを選択してください。',
  backToDashboard: 'ホームに戻る',
  JPY: '{{num}}円',
  getJPY: (num: number): string => `${num}円`,
  title: 'M&Aナビ | アカウント登録',
  manavi: 'M&Aナビ',
  footer: '© 2018 M&Aナビ',
  deleteFailed: '削除に失敗しました',
  deleteTarget: 'を削除します。',
  deleteConfirmTitle: 'ファイルを削除しますか？',
  deleteConfirm: '削除しますか？削除したら元には戻せません。',
  deleteConfirmRich: '削除したファイルは元に戻せません。\n\nファイルを削除しますか？',
  delete: '削除',
  inputWithArg: '{{title}}を入力してください', // use validation.required instead of this
  input: '入力してください',
  blank: '未記入',
  noData: '表示する案件はありません',
  countPeople: '人',
  number: {
    less: '{{num}}{{unit}}以下',
    over: '{{num}}{{unit}}以上',
    range: '{{min}}~{{max}}{{unit}}',
    getLess: (num: number, unit: string): string => `${num}${unit}以下`,
    getOver: (num: number, unit: string): string => `${num}${unit}以上`,
    getRange: (min: number, max: number, unit: string): string => `${min}~${max}${unit}`,
  },
  range: {
    min: '下限',
    max: '上限',
  },
  companyAlias: '様',
  redirectConfirm: '登録が完了していません。編集内容が失われますが、遷移しますか？',
  isDesired: {
    yes: '希望する',
    no: '希望しない',
  },
  flag: {
    yes: '有',
    no: '無',
  },
  required: '必須',
  clear: '入力値をクリア',
  switch: '切替',
}
