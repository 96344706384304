import React, { useState, useContext, createContext, Dispatch, FC } from 'react'

type LoadingState = false | 'mask' | 'progress'
interface LoadingContextType {
  loading: LoadingState
  setLoading: (loading: LoadingState) => void | Dispatch<LoadingState>
}

const LoadingContext = createContext<LoadingContextType>({
  loading: false,
  setLoading: (loading: LoadingState) => console.log(loading),
})

export function useLoading(): LoadingContextType {
  return useContext(LoadingContext)
}

export const LoadingProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [loading, setLoading] = useState<LoadingState>(false)
  const value = { loading, setLoading }
  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
}
