import React, { FC, useMemo } from 'react'
import { Link } from '@mui/material'
import { useAuth } from 'context/authContext'
import { isBankerUser } from 'utils/isBankerUser'
import { timeoutError as t } from 'public/locales/ja/components/elements/timeoutError'

export const TimeoutError: FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useAuth()
  const href = useMemo(() => {
    if (!user) {
      return
    }
    return isBankerUser(user.role) ? '/banker/sign_in' : '/users/sign_in'
  }, [user])
  return (
    <>
      <span>{t.timeout}</span>
      <span> | </span>
      {href ? (
        <Link href={href} color={'inherit'}>
          {t.reLogin}
        </Link>
      ) : (
        <span>{t.reLogin}</span>
      )}
    </>
  )
}
