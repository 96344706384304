import { UserRole } from 'types/user'

export const isBankerUser = (role: UserRole): boolean => {
  return [
    UserRole.BankOwner,
    UserRole.BankAdmin,
    UserRole.BankHQManager,
    UserRole.BankHQMember,
    UserRole.BankBranchManager,
    UserRole.BankBranchMember,
    UserRole.BankHandsOn,
    UserRole.BankReadOnlyBranchMember,
  ].includes(role)
}
