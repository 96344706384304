import { UserRole } from 'types/user'
import { convertToRegex } from 'utils/string'

export const isUserCanViewPage = (page: string, role: UserRole): boolean => {
  // acquisition
  if (convertToRegex('/acquisition/[id]/client').test(page)) {
    return ![UserRole.BankReadOnlyBranchMember].includes(role)
  }
  if (convertToRegex('/acquisition/[id]/create').test(page)) {
    return ![UserRole.BankReadOnlyBranchMember].includes(role)
  }
  if (convertToRegex('/acquisition/[id]/edit').test(page)) {
    return ![UserRole.BankReadOnlyBranchMember].includes(role)
  }
  if (convertToRegex('/acquisition/[id]/interviews').test(page)) {
    return ![UserRole.BankReadOnlyBranchMember].includes(role)
  }
  if (convertToRegex('/acquisition/[id]/logs').test(page)) {
    return ![UserRole.BankReadOnlyBranchMember].includes(role)
  }
  if (convertToRegex('/acquisition/client').test(page)) {
    return ![UserRole.BankReadOnlyBranchMember].includes(role)
  }
  if (convertToRegex('/acquisition/create').test(page)) {
    return ![UserRole.BankReadOnlyBranchMember].includes(role)
  }

  // insight
  if (convertToRegex('/insight').test(page)) {
    return [UserRole.BankOwner, UserRole.BankAdmin, UserRole.BankHQManager].includes(role)
  }

  //dm-admin
  if (page === '/dm-admin/import/acquisitions') {
    return [UserRole.BankOwner, UserRole.BankAdmin, UserRole.BankHQManager].includes(role)
  }
  if (page === '/dm-admin/import/branches') {
    return [UserRole.BankOwner, UserRole.BankAdmin].includes(role)
  }
  if (page === '/dm-admin/import/sales') {
    return [UserRole.BankOwner, UserRole.BankAdmin, UserRole.BankHQManager].includes(role)
  }
  if (page === '/dm-admin/import/users') {
    return [UserRole.BankOwner, UserRole.BankAdmin, UserRole.BankHandsOn].includes(role)
  }
  if (page === '/dm-admin/setting/users') {
    return [UserRole.BankAdmin, UserRole.BankOwner, UserRole.BankHandsOn].includes(role)
  }
  if (page === '/dm-admin/setting/teams') {
    return [UserRole.BankAdmin, UserRole.BankOwner].includes(role)
  }
  if (page === '/dm-admin/setting/branches') {
    return [UserRole.BankAdmin, UserRole.BankOwner].includes(role)
  }
  if (page === '/dm-admin/setting/lists') {
    return [UserRole.BankAdmin, UserRole.BankOwner, UserRole.BankHQManager].includes(role)
  }
  if (convertToRegex('/dm-admin/setting/forms').test(page)) {
    return [UserRole.BankOwner, UserRole.BankAdmin, UserRole.BankHQManager].includes(role)
  }
  if (page === '/dm-admin/setting/automation') {
    return [UserRole.BankAdmin, UserRole.BankOwner].includes(role)
  }
  if (convertToRegex('/dm-admin/setting/integrations').test(page)) {
    return [UserRole.BankAdmin, UserRole.BankOwner, UserRole.BankHQManager].includes(role)
  }
  if (convertToRegex('/dm-admin/setting/custom-export-setting').test(page)) {
    return [UserRole.BankAdmin, UserRole.BankOwner, UserRole.BankHQManager].includes(role)
  }
  if (convertToRegex('/dm-admin/setting/nndb').test(page)) {
    return [UserRole.BankAdmin, UserRole.BankOwner, UserRole.BankHQManager].includes(role)
  }

  // sale
  if (convertToRegex('/sale').test(page)) {
    return ![UserRole.BankReadOnlyBranchMember].includes(role)
  }

  // clients
  if (convertToRegex('/clients/[code]').test(page)) {
    return [
      UserRole.BankAdmin,
      UserRole.BankOwner,
      UserRole.BankHQManager,
      UserRole.BankHQMember,
      UserRole.BankBranchManager,
      UserRole.BankBranchMember,
      UserRole.BankReadOnlyBranchMember,
    ].includes(role)
  }
  if (convertToRegex('/clients').test(page)) {
    return [UserRole.BankAdmin, UserRole.BankOwner, UserRole.BankHQManager, UserRole.BankHQMember].includes(role)
  }
  return true
}

export const isBranchMemberCanViewPage = (page: string, isMemberBranch: boolean, role?: UserRole): boolean => {
  if (page === '/acquisition/[id]' || page === '/sale/[id]') {
    return true
  }
  if (role === UserRole.BankBranchMember) {
    return isMemberBranch
  }
  return true
}
