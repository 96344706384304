import React, { ReactElement } from 'react'

import { LinearProgress, styled } from '@mui/material'
import { useLoading } from 'context/loadingContext'

const PREFIX = 'BlankLayout'

const classes = {
  loading: `${PREFIX}-loading`,
  loadingMask: `${PREFIX}-loadingMask`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.loading}`]: {
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: theme.zIndex.modal + 1,
  },

  [`& .${classes.loadingMask}`]: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    zIndex: theme.zIndex.modal + 1,
  },
}))

export const BlankLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }): ReactElement => {
  const { loading } = useLoading()

  return (
    <Root>
      {loading === 'mask' && (
        <div className={classes.loadingMask}>
          <LinearProgress className={classes.loading} />
        </div>
      )}
      {loading === 'progress' && <LinearProgress className={classes.loading} />}
      {children}
    </Root>
  )
}
