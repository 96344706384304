import { Team } from './team'
import { EmailSetting } from 'types/emailSettingStatus'
import type { Company } from 'types/company'
import { Buyer, TypeKind } from 'types/buyer'
import { Branch } from 'types/branch'
import { Coordinator } from 'types/coordinator'
import { BankGroup } from 'types/bankGroup'

export enum UserRole {
  System = 0,
  Seller = 1,
  Buyer = 2,
  Coordinator = 3,
  BankOwner = 4,
  BankAdmin = 5,
  BankHQManager = 6,
  BankHQMember = 7,
  BankBranchManager = 8,
  BankBranchMember = 9,
  BankHandsOn = 10,
  BankReadOnlyBranchMember = 11,
}

export enum UserCoordinatorStatus {
  Initialized = 0,
  Review = 1,
  Published = 2,
  Declined = 3,
}

export enum UserIdentificationStatus {
  NotYet = 1,
  Submitted = 2,
  Approved = 3,
  Declined = 4,
}

export enum IdentificationImageFileType {
  DEFAULT = 'identification_img',
  HEALTH_INSURANCE = 'identification_health_insurance_img',
  EMPLOYMENT = 'identification_employment_img',
}

export type IdentificationImage = {
  fileName: string
  fileType: IdentificationImageFileType
}

export enum DigitsUnits {
  Thousand = 0,
  TenThousand = 1,
  OneMillion = 3,
  OneHundredMillion = 5,
}

export type DigitsUnitType =
  | DigitsUnits.Thousand
  | DigitsUnits.TenThousand
  | DigitsUnits.OneMillion
  | DigitsUnits.OneHundredMillion

export interface User {
  id: number
  email: string
  role: UserRole
  companyId: number
  firstName: string
  lastName: string
  firstNameKana: string
  lastNameKana: string
  firstNameEn?: string
  lastNameEn?: string
  birthday: string
  tel: string
  deptName: string
  positionName: string
  activationState: string
  identificationStatus: UserIdentificationStatus
  isPreferenceBannerDisplayed?: boolean
  company?: {
    corprateClassification: number | null
  }
  banker?: {
    id: number
    bankId: number
    bank: {
      name: string
      slug: string
      cifLabel: string
      intimacyLabel?: string
      rankLabel?: string
      saleStagnationDays?: number
      acquisitionStagnationDays?: number
      digitsUnit: DigitsUnitType
      bankGroups?: BankGroup[]
      interviewDefaultSettings: { isSetDefaultValue: boolean; isLimitedDisclosureRange: boolean }
      interviewDefaultSettingsUsers: BankUser[]
      interviewDefaultSettingsTeams: Team[]
    }
    branchId?: number
    branches?: Branch
  }
  buyer?: {
    id: number
    userId: number
    kind: TypeKind
  }
  coordinator?: Coordinator
  isLocked?: boolean
  isEnabledAttachmentFiles?: boolean
  userSegment?: string
  isBelongToBankGroup?: boolean
  hasGoogleSession?: boolean
  requestedAdvisorSupport?: number
}

export interface UserWithCompany extends User {
  company: Company & { prefectures: { name?: string } }
}

export interface UserInfoAdmin extends UserWithCompany {
  createdAt: string
  deletedAt: string
  lastLoginAt: string
}

export type BankUser = Omit<
  User,
  'banker' | 'buyer' | 'identificationStatus' | 'companyId' | 'birthday' | 'tel' | 'positionName' | 'activationState'
> & {
  lastLoginAt?: string
  lastNameEn: string
  firstNameEn: string
  banker?: {
    branchId?: number
    branches?: {
      name: string
      code: string
    }
    bankerManagingBranchIds?: number[]
    bankerManagingBranches?: {
      id: number
      bankId: number
      bankerId: number
      managingBranchId: number
      branch: Branch
    }[]
  }
  read?: boolean
}

export type TaskWatcherUser = Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>

export interface FavoriteUser extends User {
  company: Company
}
export interface BankUsersResponse {
  count: number
  users: BankUser[]
}

export type UserOnlyName = Pick<
  User,
  'lastName' | 'firstName' | 'lastNameKana' | 'firstNameKana' | 'deptName' | 'positionName'
>

export type GetAdminUser = User & {
  buyer: Buyer
  emailSettings: EmailSetting[]
  identificationImage: string | null
}

export enum PageTypeKey {
  seller = 1,
  buyer = 2,
}
