import axios from 'axios'
import type { SWRConfiguration } from 'swr'

export const swrGlobalOptions: SWRConfiguration = {
  // NOTE: revalidate data every 3600000ms(1h).
  focusThrottleInterval: 3600000,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  fetcher: <T>(url: string, params?: Record<string, unknown>): Promise<T> =>
    axios.get(url, params).then<T>((res) => res.data),
}
